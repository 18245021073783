<template>
  <b-modal
    id="invite-followers-modal"
    centered
    hide-footer
    title-class="col-12 p-0"
    body-class="pt-0"
    @hide="clearData"
  >
    <template #modal-title>
      <h3 class="w-100 text-center font-weight-bold">
        {{ $t('Invite followers') }}
      </h3>
    </template>
    <div class="d-flex flex-column text-center align-items-center w-100 mb-1 justify-content-center px-1">
      <h5 class="pb-1 w-75">
        Choose one or several users to invite them to follow this page
      </h5>
      <b-input-group class="input-group-merge mb-2">
        <b-input-group-prepend
          class="no-border"
          is-text
        >
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="search"
          type="search"
          class="no-border"
          :placeholder="$t('Search...')"
        />
      </b-input-group>
      <div class="d-flex flex-row w-100 justify-content-center">
        <e-dropdown
          ref="eDropdown"
          :items="users"
          type="multiple"
        />
      </div>
      <div class="d-flex justify-content-center pt-2">
        <b-button
          variant="transparent"
          class="text-primary"
          @click="closeModal"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="sendReport"
        >
          {{ $t('Invite followers - ') }}{{ this.$refs.eDropdown ? this.$refs.eDropdown.activeItems.length : 0 }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { debounce } from 'vue-debounce'
import eDropdown from '@/elements/e-dropdown'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    eDropdown,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isCompany: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    search: {
      get() {
        return this.$store.getters['publicProfile/searchUsers']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('publicProfile/setSearchUsers', search)
      }, 500),
    },
    users() {
      return this.$store.getters['publicProfile/users']
    },
  },
  watch: {
    search: {
      handler(value) {
        if (value !== null) this.searchUsers()
      },
    },
  },
  created() {
    EventBus.$on('showInviteFollowersModal', () => {
      this.$root.$emit('bv::show::modal', 'invite-followers-modal', '#focusThisOnClose')
    })
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'invite-followers-modal', '#focusThisOnClose')
      this.clearData()
    },
    clearData() {
      this.search = null
      this.$store.commit('publicProfile/setUsers', [])
    },
    searchUsers() {
      this.$store.dispatch('publicProfile/searchUsers')
    },
    async sendReport() {
      if (this.$refs.eDropdown.activeItems.length) {
        const res = await this.$store.dispatch('profileCompany/sendFollowInvitation', {
          users: this.$refs.eDropdown.activeItems,
          slug: this.$route.params.slug,
        })
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
          this.closeModal()
        }
      }
    },
  },
}
</script>
