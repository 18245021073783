<template>
  <b-card body-class="p-1">
    <div class="mb-2">
      <h4 class="font-weight-bolder">
        {{ $t("Speciality") }}
      </h4>
      <h6
        v-if="areSpecialtiesListUnrolled"
        class="mt-1 text-primary cursor-pointer"
        @click="areSpecialtiesListUnrolled = !areSpecialtiesListUnrolled"
      >
        {{ $t("Collapse list") }}
      </h6>
      <div
        :class="[
          areSpecialtiesListUnrolled ? 'list-container-open' : 'list-container',
          'overflow-auto',
        ]"
      >
        <b-form-checkbox
          v-for="speciality in specialties.specialties"
          :key="speciality.id"
          v-model="filterBySpeciality"
          :value="speciality.id"
          class="custom-control-primary mt-1"
        >
          <h5>{{ speciality.name }}</h5>
        </b-form-checkbox>
      </div>
      <h6
        v-if="!areSpecialtiesListUnrolled && specialties.more > 1"
        class="mt-1 text-primary cursor-pointer"
        @click="areSpecialtiesListUnrolled = !areSpecialtiesListUnrolled"
      >
        {{ $t("See params more", { params: specialties.more }) }}
      </h6>
    </div>
    <div v-if="page === 'jobs'">
      <h4 class="font-weight-bolder">
        {{ $t("Counties") }}
      </h4>
      <h6
        v-if="areSecondListUnroled"
        class="mt-1 text-primary cursor-pointer"
        @click="areSecondListUnroled = !areSecondListUnroled"
      >
        {{ $t("Collapse list") }}
      </h6>
      <div
        :class="[
          areSecondListUnroled ? 'list-container-open' : 'list-container',
          'overflow-auto',
        ]"
      >
        <b-form-checkbox
          v-for="item in counties.counties"
          :key="item.id"
          v-model="secondFilter"
          :value="item.id"
          class="custom-control-primary mt-1"
        >
          <h5>{{ item.name }}</h5>
        </b-form-checkbox>
      </div>
      <h6
        v-if="!areSecondListUnroled && counties.more > 1"
        class="mt-1 mb-0 text-primary cursor-pointer"
        @click="areSecondListUnroled = !areSecondListUnroled"
      >
        {{ $t("See params more", { params: counties.more }) }}
      </h6>
    </div>
  </b-card>
</template>

<script>
import { BCard, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormCheckbox,
  },
  props: {
    page: {
      type: String,
      default: 'jobs',
    },
  },
  data() {
    return {
      areSecondListUnroled: false,
      areSpecialtiesListUnrolled: false,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    counties() {
      const counties = this.translate(
        this.$store.getters['filterData/counties'],
        this.lang,
      )
      if (!this.areSecondListUnroled) {
        return {
          counties: counties.slice(0, 5),
          more: counties.length - 5,
        }
      }
      return { counties }
    },
    specialties() {
      const specialties = this.translate(
        this.$store.getters['specialties/specialties'],
        this.lang,
      )
      if (!this.areSpecialtiesListUnrolled) {
        return {
          specialties: specialties.slice(0, 5),
          more: specialties.length - 5,
        }
      }
      return { specialties }
    },
    secondFilter: {
      set(secondFilter) {
        this.$store.commit(`${this.page}/setFilterByCounty`, secondFilter)
      },
      get() {
        return this.$store.getters[`${this.page}/filterByCounty`]
      },
    },
    filterBySpeciality: {
      set(filterBySpeciality) {
        this.$store.commit(`${this.page}/setFilterBySpeciality`, filterBySpeciality)
      },
      get() {
        return this.$store.getters[`${this.page}/filterBySpeciality`]
      },
    },
  },
}
</script>
