<template>
  <div class="profile-company-lunch-meeting">
    <!-- Title -->
    <div class="lunch-meeting-info">
      <div class="lunch-meeting-info__title">
        Lunsjmøte med Norsk Helseportal
      </div>
      <div class="lunch-meeting-info__description">
        Lorem Ipsum er rett og slett dummytekst fra og for trykkeindustrien. Lorem Ipsum har vært bransjens standard for dummytekst helt siden 1500-tallet, da en ukjent boktrykker stokket en mengde bokstaver for å lage et prøveeksemplar av en bok…  Les mer
      </div>
    </div>

    <!-- Form -->
    <b-form class="lunch-meeting-form">
      <!-- Title -->
      <div class="lunch-meeting-form__title">
        Bestilling av lunsjmøte
      </div>

      <!-- Password -->
      <b-row>
        <b-col>
          <b-form-input
            type="text"
            :placeholder="$t('Enter your old password')"
          />
        </b-col>
        <b-col>
          <b-form-input
            type="text"
            :placeholder="$t('Enter your old password')"
          />
        </b-col>
      </b-row>

      <!-- Email | phone -->
      <b-row>
        <b-col>
          <b-form-input
            type="email"
            :placeholder="$t('Enter your old password')"
          />
        </b-col>
        <b-col>
          <b-form-input
            type="tel"
            :placeholder="$t('Enter your old password')"
          />
        </b-col>
      </b-row>

      <!-- Hospital | time-picker -->
      <b-row>
        <b-col>
          <v-select
            id="hospital-multiselect"
            class="select2"
            label="name"
            :placeholder="$t('Hospital')"
            :options="[{name: 'item1'}, {name: 'item2'}]"
          />
        </b-col>
        <b-col>
          <b-form-timepicker
            locale="en"
          />
        </b-col>
      </b-row>

      <!-- Description -->
      <b-row>
        <b-col>
          <b-form-textarea
            id="textarea"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          />
        </b-col>
      </b-row>

      <!-- Submit -->
      <b-row class="lunch-meeting-form__submit">
        <b-col>
          <b-form-checkbox>
            {{ `${$t("Currently working for Employer")}` }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <!-- Btn -->
          <b-button
            variant="primary"
            class="float-right"
          >
            SEND
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>

import {
  BFormInput,
  BFormTextarea,
  BForm,
  BFormTimepicker,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'LunchMeeting',
  components: {
    BForm,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormTimepicker,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
  },
}
</script>

<style>
  .select2 .vs__dropdown-toggle{
    height: 100%;
  }
</style>
