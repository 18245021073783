<template>
  <div
    class="profile-company-about"
    v-html="company.about"
  >
  </div>
</template>

<script>
import aboutSmall from '@/assets/images/pages/profile-company/about-small.svg'
import aboutBig from '@/assets/images/pages/profile-company/about-big.svg'

export default {
  name: 'AboutUs',
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      aboutSmall,
      aboutBig,
    }
  },
}
</script>
