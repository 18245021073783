<template>
  <div class="profile-company-home">
    <div
      v-if="isOwner || isEditor || isAdmin"
      class="search-posts"
    >
      <img
        :src="getProfileAvatar"
        alt="User logo"
        width="34"
        height="34"
      >
      <button @click="() => openModal(null)">
        Share your thoughts here...
      </button>
    </div>

    <Post
      v-for="item in posts"
      :key="item.slug"
      :item="item"
      :is-editor="isOwner"
      @edit-post="openModal"
      @fetch-posts="fetchPosts"
      @update-post="updatePostInfo"
    />

    <div
      class="d-flex align-items-center justify-content-center"
    >
      <b-pagination
        v-if="postsPagination && (postsPagination.total > postsPagination.per_page)"
        v-model="pageNumber"
        :per-page="postsPagination.per_page"
        :total-rows="postsPagination.total"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>

    <PostManage
      v-if="showModal"
      :post="post"
      entity="partner-company-posts"
      @hide="showModal = false"
      @add-post="addPost"
      @update-post="updatePostInfo"
    />
  </div>
</template>

<script>
import Post from '@/components/profile-company/Post.vue'
import { BPagination } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import PostManage from '@/components/modal/post-manage'

export default {
  name: 'Home',
  components: {
    Post,
    BPagination,
    PostManage,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      post: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    search() {
      return this.$store.getters['profileCompany/postSearch']
    },
    posts: {
      get() {
        return this.$store.getters['profileCompany/companyPosts']
      },
      set(posts) {
        this.$store.commit('profileCompany/setCompanyPosts', posts)
      },
    },
    isViewer() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'viewer'
    },
    isEditor() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'editor'
    },
    isAdmin() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'admin'
    },
    isOwner() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'owner'
    },
    pageNumber: {
      get() { return this.$store.getters['profileCompany/companyPostsPageNumber'] },
      set(pageNumber) {
        this.$store.commit('profileCompany/setCompanyPostsPageNumber', pageNumber)
        this.fetchPosts()
      },
    },
    postsPagination() {
      return this.$store.getters['profileCompany/companyPostsPagination']
    },
    getProfileAvatar() {
      return this.company.image || require('@/assets/images/pages/profile/user-default-avatar.svg')
    },
  },
  watch: {
    search: {
      handler() {
        this.fetchPosts()
      },
    },
  },
  created() {
    this.fetchPosts()
  },
  methods: {
    async addPost(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        this.posts.unshift(post)
      })
    },
    openModal(post) {
      this.post = post
      this.showModal = true
    },
    async fetchPosts() {
      await this.$store.dispatch('profileCompany/fetchPosts', { slug: this.company.slug, archive: false })
    },
    async updatePostInfo(slug) {
      this.$store.dispatch('posts/fetchPostById', slug).then(post => {
        const array = [...this.posts]
        const index = array.findIndex(el => el.slug === slug)
        array[index] = post
        this.posts = [...array]
      })
    },
  },
}
</script>
