<template>
  <div class="administrator-card">
    <span class="administrator-card__title">{{ $t('Page Owner') }}</span>
    <div class="info">
      <img
        :src="getLogo"
        alt="Placeholder administrator"
      >
      <div class="contact">
        <span class="contact__name">{{ company.owner.name }}</span>
        <span>{{ company.name[this.$i18n.locale] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import avatarPlaceholder from '@/assets/images/pages/profile/user-default-avatar.svg'

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarPlaceholder,
    }
  },
  computed: {
    getLogo() {
      return this.company.owner.image || this.avatarPlaceholder
    },
  },
}
</script>

<style lang="scss" scoped>
.administrator-card {
  background: white;
  border-radius: 6px;
  padding: 1.2rem;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #5E5873;
  }

  .info {
    display: flex;
    align-items: center;
    padding-top: 25px;

    img {
      border-radius: 50px;
      width: 42px;
      height: 42px;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    &__name {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      color: #5E5873;
    }
  }
}
</style>
