<template>
  <div class="e-sidebar-home">
    <b-input-group class="input-group-merge mb-1">
      <b-input-group-prepend
        class="no-border"
        is-text
      >
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="search"
        type="search"
        class="no-border"
        :placeholder="$t('Search...')"
      />
    </b-input-group>
    <InviteLetter />
    <Administrator
      class="administrator"
      :company="company"
    />
  </div>
</template>

<script>
import InviteLetter from '@/components/profile-company/sidebar/InviteLetter.vue'
import Administrator from '@/components/profile-company/sidebar/Administrator.vue'
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue'
import { debounce } from 'vue-debounce'

export default {
  name: 'Home',
  components: {
    InviteLetter,
    Administrator,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  computed: {
    search: {
      get() {
        return this.$store.getters['profileCompany/postSearch']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('profileCompany/setPostSearch', search)
      }, 1000),
    },
  },
}
</script>

<style lang="scss" scoped>
.e-sidebar-home {
  .administrator {
    margin-top: 20px;
  }
}
</style>
