<template>
  <div
    v-if="course !== null"
    class="profile-company-tv"
  >
    <webinar-card
      v-for="item in course.data"
      :id="item.id"
      :key="item.id"
      :course="item.attributes"
      :subscribed="item.attributes.is_subscribed"
      :is_own="true"
    />
    <filter-card page="myCourse" />
  </div>
</template>

<script>

import WebinarCard from '@/components/course/WebinarCard.vue'
import FilterCard from '@/components/course/FilterCard.vue'

export default {
  name: 'TV',
  components: {
    WebinarCard,
    FilterCard,
  },
  computed: {
    course() {
      return this.$store.getters['myCourse/course']
    },
  },
  created() {
    this.$store.dispatch('myCourse/fetchCourses')
  },
}
</script>
