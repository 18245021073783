<template>
  <ul class="e-navbar">
    <li
      v-for="(item, index) in getMenuItems"
      :key="index"
      :class="{ 'active': activeTab === index }"
      @click="$emit('changeTab', index)"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Menu',
  model: {
    prop: 'activeTab',
    event: 'changeTab',
  },
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuLocationTitles: [
        'Home item',
        'About us item',
        // 'Lunch meeting item',
        // 'Small group item',
        // 'TV item',
      ],
    }
  },
  computed: {
    getMenuItems() { return this.menuLocationTitles.map(i => this.$t(i)) },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_responsive.scss";

.e-navbar {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  display: flex;
  background-color: white;
  border-radius: 6px;
  display: -webkit-box;
  overflow: auto;
  max-width: 660px;

  li {
    padding: 15px 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #444444;
    &:hover {
      cursor: pointer;
    }
  }

  li.active {
    background-color: #E0E8F3;
  }
}
</style>
