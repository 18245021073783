<template>
  <div class="e-sidebar-lunch-meeting">
    <InviteLetter />
    <ContactInfo class="contact-info" />
  </div>
</template>

<script>
import InviteLetter from '@/components/profile-company/sidebar/InviteLetter.vue'
import ContactInfo from '@/components/profile-company/sidebar/ContactInfo.vue'

export default {
  name: 'LunchMeeting',
  components: {
    InviteLetter,
    ContactInfo,
  },
}
</script>

<style lang="scss" scoped>
.e-sidebar-lunch-meeting {
  .contact-info {
    margin-top: 20px;
  }
}
</style>
