<template>
  <div class="invite-email-card">
    <span>{{ $t('Invite doctors to follow this page') }}</span>
    <div class="invite-email-card__content">
      <b-button
        variant="primary"
        @click="showFollowersListModal"
      >
      {{ $t('Invite') }}
      </b-button>
    </div>
    <invite-followers-modal />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

import InviteFollowersModal from '@/components/profile-company/InviteFollowersModal/InviteFollowersModal.vue'

export default {
  components: {
    BButton,
    InviteFollowersModal,
  },
  methods: {
    openMailBox() {
      window.location.href = `mailto:?subject=Invite&body=I invite you to follow: ${window.location.href}`
    },
    showFollowersListModal() {
      EventBus.$emit('showInviteFollowersModal')
    },
  },
}
</script>

<style lang="scss" scoped>
  .invite-email-card {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: white;

    &__content {
      padding-top: 15px;
    }
  }
</style>
