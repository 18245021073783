<template>
  <div class="e-sidebar-small-group">
    <invite-letter />
    <filter-card page="myCourse" />
  </div>
</template>

<script>
import InviteLetter from '@/components/profile-company/sidebar/InviteLetter.vue'
import FilterCard from '@/components/course/FilterCard.vue'

export default {
  name: 'SmallGroup',
  components: {
    InviteLetter,
    FilterCard,
  },
}
</script>
