<template>
  <div class="e-sidebar-about">
    <InviteLetter />
    <ContactInfo
      :company="company"
      class="contact-info"
    />
    <Administrator
      :company="company"
      class="administrator"
    />
  </div>
</template>

<script>
import InviteLetter from '@/components/profile-company/sidebar/InviteLetter.vue'
import Administrator from '@/components/profile-company/sidebar/Administrator.vue'
import ContactInfo from '@/components/profile-company/sidebar/ContactInfo.vue'

export default {
  name: 'About',
  components: {
    InviteLetter,
    Administrator,
    ContactInfo,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.e-sidebar-about {
  .contact-info, .administrator {
    margin-top: 20px;
  }
}
</style>
