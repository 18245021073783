<template>
  <div>
    <not-approved-info-card v-if="showPendingPlaceholder" />
    <b-container
      v-else-if="!isEmpty(company)"
    >
      <b-card
        overlay
        body-class="d-flex align-items-end img-gradient"
        :img-src="getCoverImagePath"
        img-alt="Cover image profile company"
        :img-height="getCoverImgHeight"
      >
        <div class="header-profile-company">
          <div class="info-profile-company">
            <div class="image-container">
              <img
                :src="getImagePath"
                rounded="circle"
                alt="profile avatar"
                class="info-profile-company__logo object-fit-cover"
              >
            </div>
            <span class="info-profile-company__title">{{ company.name[$i18n.locale] || company.name.no || company.name.en }}</span>
          </div>
          <div class="follow-profile-company d-flex flex-row align-items-center">
            <span
              class="follow-profile-company__title"
              :class="{'cursor-pointer': (isOwner || isAdmin)}"
              @click="showFollowersListModal"
            >
              {{ company.followers_count }} {{ $t('followers') }}
            </span>
            <img
              :src="verticalSmallLine"
              alt="line"
              class="follow-profile-company__vertical-line"
            >
            <div
              v-if="isOwner || isAdmin || isEditor"
              class="d-flex"
            >
              <b-button
                v-if="!isEditor"
                variant="outline-primary"
                @click.stop="$router.push(`/profile_company_manage/${company.slug}`)"
              >
                Edit profile
              </b-button>
              <div class="ml-1 profile-settings-icon">
                <img
                  :src="profileSettingsIcon"
                  alt="settings"
                  @click="showSettingsList = !showSettingsList"
                >
                <div
                  v-if="showSettingsList"
                  class="profile-settings-list p-1 align-items-center"
                >
                  <div
                    class="d-flex"
                    :class="{ 'mb-1': isOwner || isAdmin}"
                    @click="openArchivedPosts"
                  >
                    <img
                      :src="archivedFolderIcon"
                      alt="settings"
                    >
                    <h5 class="pl-1 mb-0">
                      {{ $t('Archived posts') }}
                    </h5>
                  </div>
                  <div
                    v-if="isOwner || isAdmin"
                    class="d-flex"
                    @click="openProfileSettings"
                  >
                    <img
                      :src="userSettingsIcon"
                      alt="settings"
                    >
                    <h5 class="pl-1 mb-0">
                      {{ $t('Profile settings') }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <template v-else>
              <b-button
                variant="primary"
                @click="follow"
              >
                {{ company.amIFollowing ? 'Unfollow' : 'Follow' }}
              </b-button>
            </template>
          </div>
        </div>
      </b-card>

      <div class="content-profile-company">
        <!-- Navbar -->
        <div class="content-profile-company__navbar">
          <Menu
            v-model="activeTab"
          />

          <!-- Content -->
          <b-container class="content-profile-company__info nopadding">
            <component
              :is="getComponentInfo"
              :company="company"
            />
          </b-container>
        </div>

        <!-- Right content -->
        <div class="content-profile-company__sidebar">
          <component
            :is="getSidebarComponent"
            :company="company"
          />
        </div>
      </div>
      <followers-list-modal />
    </b-container>
  </div>
</template>

<script>
import verticalSmallLine from '@/assets/images/pages/profile-company/vertical-small-line.svg'
import logoPlaceholder from '@/assets/images/pages/profile-company/logo-placeholder.svg'
import coverPlaceholder from '@/assets/images/pages/profile-company/cover-placeholder.svg'
import profileSettingsIcon from '@/assets/images/pages/settings-icon.svg'
import userSettingsIcon from '@/assets/images/pages/profile-company/user_settings_icon.svg'
import archivedFolderIcon from '@/assets/images/pages/profile-company/folder_icon.svg'

import Menu from '@/components/profile-company/Menu.vue'
import FollowersListModal from '@/components/profile-company/FollowersListModal/FollowersListModal.vue'

// Content components
import Home from '@/components/profile-company/Home'
import AboutUs from '@/components/profile-company/About'
import LunchMeeting from '@/components/profile-company/LunchMeeting'
import SmallGroup from '@/components/profile-company/SmallGroup'
import TV from '@/components/profile-company/TV'

// Sidebar components
import HomeSidebar from '@/components/profile-company/sidebar/Home/Home.vue'
import AboutSidebar from '@/components/profile-company/sidebar/About/About.vue'
import LunchMeetingSidebar from '@/components/profile-company/sidebar/LunchMeeting/LunchMeeting.vue'
import SmallGroupSidebar from '@/components/profile-company/sidebar/SmallGroup/SmallGroup.vue'

import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotApprovedInfoCard from '@/components/NotApprovedInfoCard.vue'

import {
  BContainer, BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BCard,
    BButton,
    Home,
    Menu,
    AboutUs,
    LunchMeeting,
    SmallGroup,
    TV,
    HomeSidebar,
    AboutSidebar,
    LunchMeetingSidebar,
    SmallGroupSidebar,
    FollowersListModal,
    NotApprovedInfoCard,
  },
  data() {
    return {
      verticalSmallLine,
      logoPlaceholder,
      coverPlaceholder,
      profileSettingsIcon,
      userSettingsIcon,
      archivedFolderIcon,

      activeTab: 0,
      company: null,
      showPendingPlaceholder: false,
      showSettingsList: false,
    }
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.slug': {
      async handler() {
        this.fetchCompany().then(() => {
          this.fetchPosts()
        })
      },
      deep: true,
    },
  },
  setup() {
    return {
      isEmpty,
    }
  },
  computed: {
    ...mapGetters({
      currentBreakPoint: 'app/currentBreakPoint',
      user: 'auth/user',
    }),
    getComponentInfo() {
      const indexTabs = {
        0: 'Home',
        1: 'AboutUs',
        2: 'LunchMeeting',
        3: 'SmallGroup',
        4: 'TV',
      }

      return indexTabs[this.activeTab]
    },
    getSidebarComponent() {
      const indexTabs = {
        0: 'HomeSidebar',
        1: 'AboutSidebar',
        2: 'LunchMeetingSidebar',
        3: 'SmallGroupSidebar',
        4: 'AboutSidebar',
      }

      return indexTabs[this.activeTab]
    },
    getCoverImagePath() {
      return this.company.cover ?? this.coverPlaceholder
    },
    getImagePath() {
      return this.company.image ?? this.logoPlaceholder
    },
    getCoverImgHeight() {
      const sizes = {
        default: '300',
        xs: '150',
        sm: '150',
      }

      return sizes[this.currentBreakPoint] ?? sizes.default
    },
    isViewer() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'viewer'
    },
    isEditor() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'editor'
    },
    isAdmin() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'admin'
    },
    isOwner() {
      return this.user.active_business_profile.slug === this.company.slug && this.user.active_business_profile.role === 'owner'
    },
  },
  async created() {
    await this.fetchCompany()
    this.checkPermission()
  },
  methods: {
    openProfileSettings() {
      this.$router.push({
        path: `/profile_settings/${this.$route.params.slug}`,
      })
    },
    openArchivedPosts() {
      this.$router.push(`/archived_posts/${this.$route.params.slug}`)
    },
    async fetchCompany() {
      try {
        const res = await this.$store.dispatch('profileCompany/fetchCompany', this.$route.params.slug)
        const { data: { data: companies } } = res
        this.company = companies
      } catch (e) {
        if (e.response.status === 404) {
          this.$router.push('/404')
        }
        if (e.response.status === 401) {
          this.showPendingPlaceholder = true
        }

        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchPosts() {
      await this.$store.dispatch('profileCompany/fetchPosts', { slug: this.company.slug, archive: false })
    },
    showFollowersListModal() {
      if (this.isOwner) {
        EventBus.$emit(`showFollowersModalList-${true}`)
      }
    },
    async follow() {
      try {
        const res = await axiosIns.post(`/partner-company-follow/${this.company.slug}`)

        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
          this.fetchCompany()
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    checkPermission() {
      if (this.company) {
        /**
         * If this company belong to auth user
         */
        if (this.isOwner || this.isAdmin || this.isEditor || this.isViewer) {
          return true
        }

        if (this.user.user_info.approved_hrp === 2) {
          return true
        }
      }

      this.$router.push('/404')
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/abstracts/_responsive.scss";

  @mixin title($fontSize) {
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize;
    line-height: 24px;
    color: #5E5873;
  }

  .header-profile-company {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    top: 279px;
    position: absolute;
    padding-right: 1.2rem;

    @include media-q-md {
      top: 135px;
    }
    @include max-screen(400px) {
      top: 145px;
    }
  }

  .info-profile-company {
    &__title {
      @include title(30px);
      padding-left: 20px;

      @include media-q-md {
        font-size: 25px;
        padding-left: 15px;
      }
      @include media-q-sm {
        font-size: 20px;
        padding-left: 10px;
      }
    }

    &__logo {
      width: 110px;
      height: 110px;
      border-radius: 50%;

      @include media-q-md {
        width: 80px;
        height: 80px;
      }
      @include max-screen(400px) {
        width: 50px;
        height: 50px;
      }
    }

    .image-container {
      background-color: white;
      display: inline-block;
      border-radius: 50%;
    }
  }

  .follow-profile-company {
    &__title {
      @include title(16px);

      @include media-q-md {
        font-size: 14px;
      }
      @include media-q-sm {
        font-size: 12px;
      }
    }

    &__vertical-line {
      padding-left: 30px;
      padding-right: 20px;

      @include media-q-md {
        padding-left: 15px;
        padding-right: 10px;
      }
    }

    button {
      @include media-q-md {
        padding: 8px 15px;
      }
      @include max-screen(400px) {
        padding: 5px 5px;
      }
    }
  }

  .content-profile-company {
    padding-top: 90px;
    display: flex;

    &__navbar {
      width: 70%;
      @include media-q-md {
        width: 100%;
      }
    }

    &__info {
      padding-top: 25px !important;
    }

    &__sidebar {
      width: 30%;
      margin-left: 20px;
      height: fit-content;
      @include media-q-md {
        width: 100%;
        margin-left: 0px;
        padding-bottom: 25px;
      }
    }

    @include media-q-md {
      padding-top: 55px;
      flex-direction: column-reverse;
    }
    @include max-screen(400px) {
      padding-top: 30px;
    }
  }
</style>
