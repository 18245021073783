<template>
  <div class="contact-info-card">
    <div class="contact-info-card__title">{{ $t('Contact information') }}</div>
    <div class="contact-info-card__address">
      <div>{{ company.name[this.$i18n.locale] }}</div>
      <span>{{ company.contactInfo.address }}</span>
    </div>
    <div v-if="company.contactInfo.email || company.contactInfo.phone" class="contact-info-card__contact">
      <div v-if="company.contactInfo.phone">{{ $t('TEL')}}: <a :href="`tel:${company.contactInfo.phone}`">{{ company.contactInfo.phone }}</a></div>
      <div v-if="company.contactInfo.email">{{ $t('EMAIL')}}: <a :href="`tel:${company.contactInfo.email}`">{{ company.contactInfo.email }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInfo',
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .contact-info-card {
    border-radius: 6px;
    padding: 1.2rem;
    background-color: white;

    &__title {
      font-size: 1.45rem;
      font-weight: 700;
      padding-bottom: 0.7rem;
    }

    &__address {
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 0.3rem;
      span {
        font-size: 1rem;
        color: #90959A;
        font-weight: 500;
        padding-bottom: 0.7rem;
      }
    }

    &__contact {
      font-size: 1rem;
      color: #90959A;
      div:first-child {
        padding-bottom: 0.3rem;
      }
      a {
        color:#2986E8;
      }
    }
  }
</style>
